import React from "react"
import { colors, margins, fonts, fontSizes, breakpoint } from "../cms/style"
import LogoSign from "../assets/icon-logo-sign"
import { Link } from "gatsby"

import FbSVG from "../assets/sm/facebookIcon"
import YoutubeSVG from "../assets/sm/youtubeIcon.js"
import LnkdSVG from "../assets/sm/linkedinIcon.js"
import InstSVG from "../assets/sm/instagramIcon.js"
import IconAdres from "../assets/icon-adres"
import IconInfo from "../assets/icon-info"

import GooglePartner from "../assets/GooglePartner.svg"
//import GooglePartner from "../assets/google_logo2.png"//
//import GooglePartner from "../assets/google_logo1.jpg"//

const LeftSection = () => (
  <div css={styles.leftSection}>
    <div css={{ fontFamily: fonts.headerFont, fontWeight: 700 }}>
      <p style={{ fontSize: fontSizes.fz3.xl, lineHeight: 1 }}>RDB Group</p>
    </div>
    <div
      css={{
        display: "flex",
        alignItems: "center",
        marginTop: "2rem",
        marginBottom: "2rem",
        [breakpoint.xs]: { margin: "16px 0" },
      }}
    >
      <div css={{ marginRight: 8, svg: { width: 16, marginRight: 16 } }}>
        <IconAdres />
      </div>
      <div css={{ fontFamily: fonts.footerFont, fontWeight: 400 }}>
        <p>
          Olivia Business Centre
          <br />
          al. Grunwaldzka 472C,
          <br />
          80-309 Gdańsk,
          <br />
          Polska (Poland)
        </p>
      </div>
    </div>
    <div
      css={{
        display: "flex",
        alignItems: "center",
        // marginTop: "2rem",//
        //marginBottom: "2rem",//
      }}
    >
      <div css={{ marginRight: 8, svg: { width: 16, marginRight: 16 } }}>
        <IconInfo />
      </div>
      <div css={{ fontFamily: fonts.footerFont, fontWeight: 400 }}>
        <p>
          KRS: <b>381639400</b>
        </p>
        <p>
          NIP: <b>5842778174</b>
        </p>
      </div>
      {/* <div css={[sPartnerSectionDesktop]}>
        <a
          href="https://www.google.com/partners/agency?id=4510081843"
          rel="noreferrer noopener"
        >
          <img
            src={GooglePartner}
            alt="Google Partner"
            css={{ maxWidth: 200 }}
          />
        </a>
      </div> */}
    </div>
  </div>
)

const CenterSection = () => (
  <div css={styles.centerSection}>
    <div
      css={{
        width: "60%",
        height: "60%",
        svg: {
          height: "100%",
          width: "100%",
          opacity: ".3",
          marginBottom: 75,
          marginTop: 25,
          [breakpoint.xs]: {
            marginTop: 25,
            marginBottom: 25,
          },
        },
      }}
    >
      <LogoSign />
    </div>
    <div css={[sPartnerSection]}>
      <img
        src={GooglePartner}
        alt="Google Partner"
        // css={{ maxWidth: 200, marginRight: "30px !important" }}
      />
    </div>
  </div>
)

const SocialMediasInner = () => (
  <>
    <a
      href="https://www.instagram.com/rdbgroup_pl/"
      target="_blank"
      rel="noreferrer"
    >
      <InstSVG />
    </a>
    <a
      href="https://www.linkedin.com/company/rdbgroup/"
      target="_blank"
      rel="noreferrer"
    >
      <LnkdSVG />
    </a>
    <a
      href="https://www.youtube.com/channel/UCwYFJ0t9YQ7HdfPB9W1-sHg"
      target="_blank"
      rel="noreferrer"
    >
      <YoutubeSVG />
    </a>
    <a
      href="https://www.facebook.com/rdbgroup/"
      target="_blank"
      rel="noreferrer"
    >
      <FbSVG />
    </a>
  </>
)

const RightSection = () => (
  <div css={styles.rightSection}>
    <div css={styles.socialMediasNotMob}>
      <SocialMediasInner />
    </div>
    <div
      css={{
        a: {
          display: "inline-block",
          color: "#444",
          marginBottom: 8,
          textDecoration: "none",
        },
        [breakpoint.xs]: { margin: "16px 0" },
      }}
    >
      <div>
        <Link css={{ color: "#333", fontWeight: 700 }} to={"/dla-mediow"}>
          DLA MEDIÓW
        </Link>
      </div>
      <div css={{ fontFamily: fonts.footerFont, fontWeight: 400 }}>
        <a href="tel:+48798669977" rel="noreferrer" target="_blank">
          +48 798 669 977
        </a>
        <br />
        <a href="mailto:biuro@rdbgroup.pl" rel="noreferrer" target="_blank">
          biuro@rdbgroup.pl
        </a>
      </div>
    </div>
    <div css={{ fontFamily: fonts.footerFont, fontWeight: 400 }}>
      <p>
        <b>
          <a css={{ textDecoration: "none" }} href="/polityka-prywatnosci">
            Polityka prywatności
          </a>
        </b>
      </p>
      <p>Copyright © 2024 RDB Group</p>
    </div>
  </div>
)

export default function S4footer() {
  return (
    <footer css={styles.mainSection}>
      <div css={styles.inner}>
        <div css={styles.socialMediasIsMob}>
          <SocialMediasInner />
        </div>
        <LeftSection />
        <CenterSection />
        <RightSection />
      </div>
    </footer>
  )
}

const styles = {
  mainSection: {
    position: "relative",
    minHeight: "35vh",
    backgroundColor: "white",

    borderRadius: "128px 128px 0 0",
    zIndex: 999,
    height: "max-content",
    "@media screen and (min-width: 992px)": {
      boxShadow: "0 -16px 64px 0 rgba(0,0,0,0.04)",
    },

    "p, a": {
      opacity: 1,
      color: "#333",
      fontSize: fontSizes.fz4.xl,
    },

    [breakpoint.lg]: {
      minHeight: "50vh",

      "p, a": {
        fontSize: fontSizes.fz4.lg,
      },
    },
    [breakpoint.md]: {
      minHeight: "50vh",

      "p, a": {
        fontSize: fontSizes.fz4.md,
      },
    },
    [breakpoint.sm]: {
      minHeight: "50vh",

      "p, a": {
        fontSize: fontSizes.fz4.sm,
      },
    },
    [breakpoint.xs]: {
      minHeight: "50vh",
      borderRadius: "64px 64px 0 0",

      "p, a": {
        fontSize: fontSizes.fz4.xs,
      },
    },
  },

  inner: {
    display: "flex",
    margin: "0 auto",
    padding: `64px ${margins.sectionsX.xl}px ${
      margins.topBtmGradientHeight.xl / 2
    }px`,
    width: "100%",
    maxHeight: 450,
    position: "relative",
    // width: 'calc(80% - 80px + 32px)',

    "&>div": {
      width: "33.3333%",
      [breakpoint.sm]: { width: "50%" },
      [breakpoint.xs]: { width: "100%" },
    },

    [breakpoint.lg]: {
      padding: `64px ${margins.sectionsX.lg}px ${
        margins.topBtmGradientHeight.lg / 2
      }px`,
    },
    [breakpoint.md]: {
      padding: `80px ${margins.sectionsX.md}px ${
        margins.topBtmGradientHeight.md / 2
      }px`,
      maxHeight: 448,
    },
    [breakpoint.sm]: {
      padding: `80px ${margins.sectionsX.sm}px ${
        margins.topBtmGradientHeight.sm / 2
      }px`,
    },
    [breakpoint.xs]: {
      padding: `64px ${margins.sectionsX.xs}px ${
        margins.topBtmGradientHeight.xs / 2
      }px`,
      flexDirection: "column",
    },
  },

  leftSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [breakpoint.xs]: { marginBottom: 32 },
  },
  centerSection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",

    // [breakpoint.sm]: { display: "none" },
  },
  rightSection: {
    textAlign: "right",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [breakpoint.xs]: { textAlign: "left", paddingBottom: 100 },
  },

  socialMediasNotMob: {
    display: "flex",
    justifyContent: "flex-end",

    svg: {
      width: 36,
      height: 36,
      marginLeft: 16,

      path: {
        fill: colors.other.lightBlack,
      },
    },

    [breakpoint.xs]: { display: "none" },
  },

  socialMediasIsMob: {
    display: "none",

    [breakpoint.xs]: {
      position: "absolute",
      right: 24,
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
      width: "auto!important",
      height: 340,
      svg: { margin: "0 16px 0 0", width: 36, height: 36 },
    },
  },
}

const sPartnerSection = {
  display: "flex",
  flexDirection: "column",

  // [breakpoint.md]: {
  //   marginLeft: "1rem",
  //   padding: "2rem 2rem 2rem",
  //   justifyContent: "flex-start",
  //   alignItems: "flex-start",
  // },

  // [breakpoint.sm]: {
  padding: "4rem 0 2rem",
  justifyContent: "center",
  alignItems: "center",
  // },
  img: {
    width: 160,
    height: 160,
  },
}

const sPartnerSectionDesktop = {
  display: "none",
  img: {
    width: 160,
    height: 160,
    // marginRight: 130,
    // marginTop: 30,
  },
  "@media(min-width: 768px)": {
    display: "flex",
    // transform: "translateX(27vw)",
    img: {
      width: 160,
      height: 160,
    },
  },
}
